.program-collapsible {
  margin: 20px 0;
  max-height: 72px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;

  .subheading,
  i {
    transition: color 0.2s ease-in-out;
  }

  i {
    transition: transform 0.3s ease-in-out;
    font-size: 30px;
  }

  .program-collapsible__heading {
    max-width: 1300px;
    padding: 0 50px;
    border: 2px solid $primary;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }

  &:hover {
    .program-collapsible__heading {
      background-color: $primary;
    }

    i {
      color: white;
    }

    .subheading {
      color: white;
    }
  }

  &.open {
    max-height: 2000px;
    padding-bottom: 15px;

    .program-collapsible__heading {
      background-color: $secondary;
    }

    i {
      color: white;
      transform: rotateZ(180deg);
    }

    .turn-in {
      transform: rotateZ(-180deg);
    }

    .subheading {
      color: white;
    }
  }
}

//button switching containers

.online-course-container {
  display: block;

  &.hide {
    display: none;
  }
}

@media screen and (max-width: $phone-large) {
  .program-collapsible {
    .program-collapsible__heading {
      padding: 0 7px;

      .subheading {
        font-size: 20px;
        margin-left: 5px;

        .hide-wording {
          display: none;
        }
      }

      i {
        font-size: 20px;
        margin-right: 10px;
      }

      .turn-in {
        display: none;
      }
    }
  }
}
