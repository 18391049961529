html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

*, :before, :after {
  box-sizing: border-box;
}

::selection {
  color: #fff;
  background-color: #e4002b;
}

body {
  font-family: Montserrat, sans-serif;
  overflow-x: hidden;
  overflow-y: visible !important;
}

h1, h2, h3, h4, p {
  color: #002149;
  padding: 0 5px;
}

p {
  color: #002149;
  line-height: 1.75;
}

.text-center, .text-center > * {
  text-align: center;
}

.text-block {
  max-width: 1000px;
  width: 90%;
}

.top-heading, .program__student-story .heading, .top-heading-left, .top-heading-small {
  text-align: center;
  color: #002149;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-top: 25px;
  margin-bottom: 0;
  font-size: 60px;
  font-weight: 200;
}

.top-heading-small {
  font-size: 40px;
}

.top-heading-left {
  text-align: left;
}

.sub-heading, .program__student-story .sub-heading, .program__student-story .sub-heading-small, .program__student-story .sub-heading-left, .program__student-story .sub-heading-small-left, .sub-heading-small-left, .sub-heading-left, .sub-heading-small {
  color: #002149;
  text-align: center;
  margin-top: 10px;
  font-size: 36px;
  font-weight: 500;
}

.sub-heading-small {
  font-size: 24px;
}

.sub-heading-left {
  text-align: left;
}

.sub-heading-small-left {
  text-align: left;
  font-size: 24px;
}

.cta {
  text-align: center;
  font-size: 1.2rem;
  line-height: 2rem;
}

.color-white {
  color: #fff;
}

.color-black {
  color: #000;
}

.no-dec {
  text-decoration: none;
}

.inline-link {
  color: #e4002b;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  transition: color .2s ease-in-out;
}

.inline-link:hover {
  color: #002149;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.color-secondary {
  color: #e4002b;
}

ul {
  padding-left: 40px;
}

li {
  color: #002149;
  line-height: 1.5;
}

.styled-list li {
  color: #002149;
  margin-bottom: 10px;
  font-weight: bold;
  list-style: none;
}

.img-break {
  max-height: 600px;
  overflow: hidden;
}

.img-break img {
  z-index: -1;
  margin: 0;
  position: relative;
  box-shadow: 0 0 30px 10px #00000075;
}

.img-width-contain {
  max-width: 400px;
}

.top-banner-img {
  margin: 0 0 25px;
  box-shadow: 0 0 20px 10px #0000007c;
}

img {
  width: 100%;
  margin: 15px 0;
}

.badge-large {
  width: 400px;
}

.badge-med {
  width: 300px;
}

.badge-small {
  width: 150px;
  margin: 0 20px;
}

.badge-glide {
  width: 150px;
}

@media screen and (width <= 550px) {
  .badge-small, .badge-large {
    width: 280px;
  }
}

@media screen and (width <= 475px) {
  .badge-small, .badge-large {
    width: 240px;
  }
}

.block {
  max-width: 1300px;
  margin: 0 auto;
  padding: 30px 60px;
}

.block-max {
  margin: 0 auto;
  padding: 30px 60px;
}

.block-flipsnack {
  width: 100%;
  margin: 0 auto;
  padding: 30px 60px;
}

.block-large {
  margin-bottom: 60px;
  padding: 100px 0 30px;
}

.nav-btn {
  letter-spacing: 1px;
  min-width: 146px;
  height: 32px;
  text-align: center;
  border: 0;
  border-radius: 5px;
  outline: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
}

.btn, .btn__small-text {
  cursor: pointer;
  letter-spacing: 1px;
  text-transform: capitalize;
  text-align: center;
  min-width: 250px;
  min-height: 60px;
  border: 0;
  border-radius: 30px;
  outline: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 2rem;
  text-decoration: none;
  display: flex;
  box-shadow: 0 5px 20px #00000081;
}

.btn i, .btn__small-text i {
  margin-right: 25px;
  font-size: 2rem;
}

.btn__small-text {
  font-size: 1.2rem;
  font-weight: bold;
}

.btn__small-text i {
  margin-right: 10px;
  font-size: 1.5rem;
}

.btn-tall {
  cursor: pointer;
  letter-spacing: 1px;
  text-transform: capitalize;
  text-align: center;
  min-width: 250px;
  min-height: 100px;
  border: 0;
  border-radius: 30px;
  outline: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1.5rem;
  text-decoration: none;
  display: flex;
  box-shadow: 0 5px 20px #00000081;
}

.btn-primary {
  color: #fff;
  background: #002149;
  border: 2px solid #002149;
  transition: background-color .2s, color .2s;
}

.btn-primary:hover, .btn-primary.active {
  color: #002149;
  background-color: #fff;
}

.btn-secondary {
  color: #fff;
  background: #e4002b;
  border: 2px solid #e4002b;
  transition: background-color .2s, color .2s;
}

.btn-secondary:hover, .btn-secondary.active {
  color: #e4002b;
  background-color: #fff;
}

.btn-outline {
  color: #002149;
  background: #fff;
  border: 2px solid #002149;
  transition: background-color .2s, color .2s;
}

.btn-outline:hover {
  color: #e4002b;
  background-color: #fff;
}

.btn-block {
  height: 150px;
  width: 350px;
  max-width: 500px;
  background-color: #e4002b;
  border: 2px solid #e4002b;
  transition: background-color .2s, color .2s;
}

.btn-block p {
  color: #fff;
}

.btn-block:hover {
  color: #e4002b;
  background-color: #fff;
}

.btn-block:hover p {
  color: #e4002b;
}

.btn-block-primary {
  width: 350px;
  height: 150px;
  background-color: #002149;
  border: 2px solid #002149;
  transition: background-color .2s, color .2s;
}

.btn-block-primary p {
  color: #fff;
}

.btn-block-primary:hover {
  color: #e4002b;
  background-color: #fff;
}

.btn-block-primary:hover p {
  color: #002149;
}

.btn-group {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.grid-1 {
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  display: grid;
}

.grid-2-col {
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 0;
  display: grid;
}

.grid-2-col-stagger {
  grid-template-columns: 1fr 2fr;
  display: grid;
}

.grid-3-col {
  width: 100%;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  display: grid;
}

.grid-4-col {
  width: 100%;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(4, 1fr);
  gap: 0;
  padding: 30px 0;
  display: grid;
}

.grid-1 > *, .grid-2-col > *, .grid-2-col-stagger > *, .grid-3-col > *, .grid-4-col > * {
  width: 95%;
  place-self: flex-start center;
  margin: 15px;
}

.grid-2-row {
  width: 100%;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(1, 1fr);
  gap: 0;
  display: grid;
}

.grid-2-row > * {
  place-self: center;
}

.align-center {
  align-self: center;
}

.grid-border {
  border: 1px solid #002149;
}

.no-gap {
  gap: 0;
}

@media screen and (width <= 1100px) {
  .grid-2-col, .grid-2-col-stagger, .grid-3-col, .grid-4-col {
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
    display: grid;
  }

  .grid-1 > *, .grid-2-col > *, .grid-2-col-stagger > *, .grid-3-col > *, .grid-4-col > * {
    place-self: flex-start center;
    margin: 10px;
  }
}

.gap {
  gap: 50px;
}

.flex-column, .btn-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.flex-column-align {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.flex-column-start {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.flex-column-end {
  flex-direction: column;
  justify-content: end;
  align-items: center;
  display: flex;
}

.flex-column-just-start {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.flex-column-between {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.flex-row {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.flex-row-between {
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.flex-row-around {
  flex-flow: wrap;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.flex-row-start {
  flex-flow: wrap;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.flex-row-just-start {
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.responsive-table-container {
  width: 100%;
  padding: 15px 0 55px;
  overflow-x: auto;
}

table {
  min-width: 260px;
  width: 85%;
  border-collapse: collapse;
  margin: 15px auto 10px;
  box-shadow: 0 20px 50px #0000007a;
}

thead {
  letter-spacing: 1.5px;
  border-bottom: 2px solid #002149;
  margin: 10px;
  font-size: 20px;
}

thead td {
  color: #002149;
  border: 2px solid #002149;
  padding: 7px 5px;
  font-weight: 500;
}

thead h2 {
  margin-top: 10px;
  margin-bottom: 15px;
}

thead.center td {
  text-align: center;
}

tbody {
  border: 2px solid #002149;
}

tbody td {
  height: 35px;
  color: #002149;
  border: 1px solid #002149;
  padding: 7px 12px;
}

tbody td a {
  color: #e4002b;
  margin: 10px 5px;
  font-weight: bold;
  text-decoration: none;
}

tbody td:nth-of-type(2n) {
  color: #fff;
  background-color: #002149;
  border-bottom: 1px solid #fff;
}

tbody.center td {
  text-align: center;
}

.spacer-top {
  padding-top: 30px;
}

.spacer-bottom {
  padding-bottom: 30px;
}

.collapsible {
  max-height: 75px;
  padding: 0 5px;
  transition: max-height .5s ease-in-out;
  overflow: hidden;
}

.collapsible.zero {
  max-height: 0;
}

.collapsible .collapsible__notifier {
  max-width: 900px;
  cursor: pointer;
  border: 3px solid #002149;
  border-radius: 25px;
  margin: 0 auto;
  padding: 10px 15px;
  transition: all .3s ease-in-out;
}

.collapsible .collapsible__notifier .sub-heading, .collapsible .collapsible__notifier .sub-heading-small, .collapsible .collapsible__notifier .sub-heading-left, .collapsible .collapsible__notifier .sub-heading-small-left {
  margin: 0;
}

.collapsible li {
  width: 100%;
  border-bottom: 2px solid #fff;
  padding: 5px 10px;
}

.collapsible.expanded {
  max-height: -moz-fit-content;
  max-height: fit-content;
}

.collapsible.expanded .collapsible__notifier {
  background-color: #e4002b;
  border: 3px solid #fff;
}

.collapsible.expanded .collapsible__notifier .top-heading, .collapsible.expanded .collapsible__notifier .program__student-story .heading, .program__student-story .collapsible.expanded .collapsible__notifier .heading, .collapsible.expanded .collapsible__notifier .top-heading-small, .collapsible.expanded .collapsible__notifier .top-heading-left {
  color: #fff;
}

.switcher {
  max-height: 75px;
  padding: 0 5px;
  transition: max-height .5s ease-in-out;
  overflow: hidden;
}

.switcher.zero {
  max-height: 0;
}

.switcher table {
  box-shadow: 0 2px 10px #00000070;
}

.switcher .switcher__button {
  max-width: 900px;
  cursor: pointer;
  border: 3px solid #002149;
  border-radius: 25px;
  margin: 0 auto;
  padding: 10px 15px;
  transition: all .3s ease-in-out;
}

.switcher .switcher__button .sub-heading, .switcher .switcher__button .sub-heading-small, .switcher .switcher__button .sub-heading-left, .switcher .switcher__button .sub-heading-small-left {
  margin: 0;
}

.switcher li {
  width: 100%;
  border-bottom: 2px solid #fff;
  padding: 5px 10px;
}

.switcher.open {
  max-height: -moz-fit-content;
  max-height: fit-content;
}

.switcher.open .switcher__butoon {
  background-color: #e4002b;
  border: 3px solid #fff;
}

.switcher.open .switcher__butoon .top-heading, .switcher.open .switcher__butoon .program__student-story .heading, .program__student-story .switcher.open .switcher__butoon .heading, .switcher.open .switcher__butoon .top-heading-small, .switcher.open .switcher__butoon .top-heading-left {
  color: #fff;
}

.expanded .collapsible {
  max-height: 100vh;
}

@media screen and (width <= 618px) {
  .collapsible {
    max-height: 77px;
  }
}

.contact-block {
  width: 90%;
  background-color: #002149;
  border-radius: 30px;
  padding: 20px 5px;
  box-shadow: 0 20px 50px #0007;
}

.contact-block h2 {
  color: #fff;
  text-shadow: 3px 3px 5px #000;
}

.contact-block h3 {
  color: #fff;
  padding-top: 10px;
}

.contact-block p {
  color: #fff;
  margin: 5px;
}

.contact-block .inline-link:hover {
  color: #fff;
}

.site-search-results table {
  box-shadow: 0 5px 10px #00000070;
}

.site-search-bar, .mobile-site-search-bar {
  background-color: #fff;
  border-radius: 25px;
  flex-wrap: nowrap;
  margin-right: 10px;
  padding: 3px 5px;
  box-shadow: 0 5px 10px #00000071;
}

.site-search-bar form, .mobile-site-search-bar form {
  flex-wrap: nowrap;
}

.site-search-bar input, .mobile-site-search-bar input {
  color: #002149;
  width: 100%;
  border: 0;
  outline: 0;
  font-size: .9rem;
}

.site-search-bar__search-btn {
  width: 25px;
  height: 25px;
  cursor: pointer;
  background-color: #e4002b;
  border: 0;
  border-radius: 50%;
  outline: 0;
  transition: all .2s;
  box-shadow: 0 0 5px #00000067;
}

.site-search-bar__search-btn i {
  color: #fff;
  font-size: .9rem;
}

.site-search-bar__search-btn:hover {
  transform: scale(1.05);
  box-shadow: 0 2px 5px #00000067;
}

.mobile-site-search-bar {
  width: 95%;
  margin-bottom: 10px;
  display: none;
}

.mobile-site-search-bar .site-search-bar__search-btn {
  width: 20%;
  min-width: 55px;
  border-radius: 25px;
}

@media screen and (width <= 768px) {
  .site-search-bar, .mobile-site-search-bar {
    display: none;
  }

  .mobile-site-search-bar {
    display: block;
  }
}

.line {
  width: 90%;
  height: 3px;
  background-image: radial-gradient(circle, #002149 0%, #ff009b00 100%);
  margin: 30px auto;
}

.verticle-line {
  width: 3px;
  height: 90%;
  background-image: radial-gradient(circle, #002149 0%, #ff009b00 100%);
  margin: 10px 20px;
}

.blue-break {
  width: 100%;
  height: 40px;
  background: #002149;
  box-shadow: 0 0 20px 10px #00000075;
}

.stop-scroll {
  overflow-y: hidden !important;
}

.width-95 {
  min-width: 95%;
}

.width-100 {
  max-width: 100px;
}

.width-200 {
  max-width: 200px;
}

.width-300 {
  max-width: 300px;
}

.width-300.make-squircle {
  border-radius: 30px;
}

.width-400 {
  max-width: 400px;
}

.width-500 {
  max-width: 500px;
}

.width-600 {
  max-width: 600px;
}

.height-stretch {
  height: 100%;
}

.width-stretch {
  width: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

.op-zero {
  opacity: 0;
}

.margin-center {
  margin: 0 auto;
}

.margin-top {
  margin-top: 20px;
}

.margin-zero {
  margin: 0;
}

.margin-25 {
  margin: 25px;
}

.margin-15 {
  margin: 15px;
}

.position-relative {
  position: relative;
}

.display-none {
  display: none;
}

.hide-container-edge {
  width: 100%;
  overflow-x: hidden;
}

.copywrite-container {
  background: #e4002b;
}

.copywrite-container .copywrite {
  color: #fff;
  text-align: center;
  padding: 10px 20px 40px;
  font-size: 12px;
  line-height: 1.5;
}

.privacy-policy {
  width: 95%;
}

.privacy-policy h1 {
  text-align: center;
}

lite-youtube {
  border-radius: 20px;
  margin: 15px auto;
  box-shadow: 0 20px 50px #0000007c;
}

.iframe-container {
  width: 100%;
  max-width: 800px;
  border-radius: 20px;
  margin: 15px auto;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 20px 50px #0000007c;
}

.responsive-iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.make-squircle {
  border-radius: 45px;
  box-shadow: 0 10px 30px #0000007c;
}

.make-squircle-blue {
  background-color: #002149;
  border-radius: 45px;
  padding: 5px;
  box-shadow: 0 10px 30px #0000007c;
}

.make-squircle-blue p, .make-squircle-blue h3 {
  color: #fff;
}

.squircle-bg {
  max-width: 300px;
  min-width: 300px;
  height: 300px;
  background-color: #002149;
  border-radius: 25px;
  margin: 10px;
  padding: 10px;
  box-shadow: 0 10px 30px #0000007c;
}

.squircle-bg h3, .squircle-bg h4, .squircle-bg p, .squircle-bg li {
  color: #fff;
  line-height: 20px;
}

.squircle-bg .btn, .squircle-bg .btn__small-text {
  min-height: 60px;
}

.squircle-bg img {
  width: 100%;
  border-radius: 20px;
  margin: 0;
  box-shadow: 0 10px 35px #0000009f;
}

.squircle-bg-large {
  max-width: 90%;
  height: auto;
  background-color: #002149;
  border-radius: 25px;
  margin: 10px;
  padding: 10px;
  box-shadow: 0 10px 30px #0000007c;
}

.squircle-bg-large h3, .squircle-bg-large h4, .squircle-bg-large p, .squircle-bg-large li {
  color: #fff;
  line-height: 1.25;
}

@media screen and (width <= 680px) {
  .squircle-bg {
    height: auto;
    margin: 10px 0;
  }

  .flex-column-start {
    align-items: center;
  }
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

@media screen and (width >= 2460px) {
  .nav-btn {
    height: 50px;
    min-width: 200px;
    padding: 0 10px;
    font-size: 1.5rem;
  }
}

@media screen and (width <= 1023px) {
  .flex-column-start {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .top-heading, .program__student-story .heading, .top-heading-small, .top-heading-left {
    font-size: 40px;
    font-weight: 300;
  }

  .sub-heading, .program__student-story .sub-heading, .program__student-story .sub-heading-small, .program__student-story .sub-heading-left, .program__student-story .sub-heading-small-left, .sub-heading-small, .sub-heading-left, .sub-heading-small-left {
    font-size: 26px;
    font-weight: 300;
  }

  h1, h2, h3, h4, p {
    color: #002149;
    text-align: center;
    padding: 0 5px;
  }
}

@media screen and (width <= 950px) {
  .top-heading, .program__student-story .heading, .top-heading-small, .top-heading-left {
    font-size: 40px;
    font-weight: 300;
  }

  .sub-heading, .program__student-story .sub-heading, .program__student-story .sub-heading-small, .program__student-story .sub-heading-left, .program__student-story .sub-heading-small-left, .sub-heading-small, .sub-heading-left, .sub-heading-small-left {
    font-size: 26px;
    font-weight: 300;
  }

  .block {
    padding: 30px 10px;
  }
}

@media screen and (width <= 550px) {
  .top-heading, .program__student-story .heading, .top-heading-small, .top-heading-left {
    font-size: 34px;
    font-weight: 300;
  }

  .sub-heading, .program__student-story .sub-heading, .program__student-story .sub-heading-small, .program__student-story .sub-heading-left, .program__student-story .sub-heading-small-left, .sub-heading-small, .sub-heading-left, .sub-heading-small-left {
    font-size: 20px;
    font-weight: 300;
  }

  .block-max {
    padding: 60px 0;
  }
}

.nav-bar-container {
  height: 70px;
  z-index: 5;
  background-color: #e4002b;
  transition: background-color .2s;
  position: sticky;
  top: 0;
  box-shadow: 0 5px 20px #00000080;
}

.nav-bar {
  height: 70px;
  z-index: 10;
  background-color: #e4002b;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(3, 1fr);
  gap: 0;
  padding: 0 30px;
  transition: background-color .2s;
  display: grid;
  position: sticky;
  top: 0;
  box-shadow: 0 5px 20px #00000080;
}

.nav-bar img {
  width: 60px;
  border-radius: 5px;
  margin: 0 10px 0 0;
  padding: 5px;
}

.nav-bar .btn-group1 {
  flex-wrap: nowrap;
  place-self: center flex-start;
}

.nav-bar .btn-group1 .nav-btn {
  margin-right: 50px;
  text-decoration: none;
  transition: color .2s, border .2s, background-color .2s, opacity .2s;
}

.nav-bar .btn-group2 {
  flex-flow: row;
  justify-content: center;
  place-self: center;
  align-items: center;
  display: flex;
}

.nav-bar .btn-group2 .nav-btn {
  height: 50px;
  text-decoration: none;
  transition: color .2s, border .2s, background-color .2s, opacity .2s;
}

.nav-bar .btn-group3 {
  flex-direction: row;
  justify-content: center;
  place-self: center flex-end;
  align-items: center;
  display: flex;
}

.nav-bar .btn-group3 #menu-label {
  color: #fff;
  margin: 0 10px 0 0;
  font-size: 1.5rem;
  font-weight: bold;
  transition: color .3s;
}

.nav-bar .btn-group3 .nav-btn {
  margin-right: 50px;
  text-decoration: none;
  transition: color .2s, border .2s, background-color .2s, opacity .2s;
}

.nav-bar.menu-open {
  background-color: #fff;
}

.nav-bar.menu-open #menu-label {
  color: #e4002b;
}

.menu-btn {
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  transition: all .2s ease-out;
  display: flex;
}

.menu-btn .menu-btn__line {
  width: 36px;
  height: 5px;
  background-color: #fff;
  margin-bottom: 5px;
  transition: all .2s ease-out;
}

.menu-btn.close {
  transform: rotate(360deg);
}

.menu-btn.close .menu-btn__line:first-child {
  background-color: #e4002b;
  transform: rotate(45deg)translate(5px, 9.5px);
}

.menu-btn.close .menu-btn__line:nth-child(2) {
  opacity: 0;
}

.menu-btn.close .menu-btn__line:nth-child(3) {
  background-color: #e4002b;
  transform: rotate(-45deg)translate(5px, -9.5px);
}

.nav-bar__menu {
  z-index: 10;
  width: 100vw;
  height: calc(100vh - 70px);
  background-color: #fff;
  transition: all .5s;
  position: fixed;
  overflow: hidden;
  transform: translateX(4000px);
}

.nav-bar__menu .mobile-nav-btns {
  margin-top: 40px;
  display: none;
}

.nav-bar__menu nav {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  display: flex;
}

.nav-bar__menu nav p, .nav-bar__menu nav .link {
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #002149;
  cursor: pointer;
  margin: 10px 20px 5px;
  padding: 0;
  font-size: 36px;
  line-height: 1.15;
  text-decoration: none;
  transition: color .2s;
}

.nav-bar__menu nav p:hover, .nav-bar__menu nav .link:hover, .nav-bar__menu nav p.active, .nav-bar__menu nav .link.active {
  color: #e4002b;
}

.nav-bar__menu nav .spacer-for-line {
  margin-bottom: 15px;
}

.nav-bar__menu nav .link-underline {
  color: #0000;
  width: 0;
  height: 3px;
  background-color: #002149;
  margin-bottom: 10px;
  margin-left: 20px;
  transition: width .2s ease-in, color .2s;
}

.nav-bar__menu nav .link-underline.active {
  background-color: #e4002b;
}

.spotlight {
  width: 85%;
  height: 150px;
  background: #fff;
  border-radius: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 15px;
  text-decoration: none;
  transition: transform .2s, box-shadow .2s;
  display: flex;
  box-shadow: 0 10px 40px #000000bd;
}

.spotlight:hover {
  transform: scale(1.02);
  box-shadow: 0 10px 40px #00000080;
}

.spotlight:nth-of-type(2) img {
  order: 2;
}

.spotlight img {
  width: 175px;
  border-radius: 10px;
  box-shadow: 0 5px 20px #00000093;
}

.spotlight .text-container {
  width: 60%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
  display: flex;
}

.spotlight .text-container h3 {
  text-align: center;
  color: #002149;
  margin-bottom: 0;
  font-size: 26px;
  text-decoration: none;
}

.spotlight .text-container p {
  text-align: center;
  margin-top: 10px;
}

.nav-bar__menu.open {
  transform: translateX(0);
}

.nav-header {
  text-align: center;
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-radius: 3px;
  margin: 40px 0;
  font-size: 44px;
  font-weight: 200;
  transition: color .15s, background-color .15s;
}

.nav-header:hover {
  color: #e4002b;
  background-color: #fff;
}

.nav-section-header {
  color: #fff;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 15px;
  font-size: 20px;
}

.nav-link__menu {
  min-height: 550px;
  width: 675px;
  opacity: 0;
  background-color: #002149;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: opacity .15s ease-in-out, transform .3s ease-in-out;
  display: flex;
  position: absolute;
  top: 8%;
  right: 4%;
  transform: translateX(1000px);
  box-shadow: 0 20px 70px #0000008f;
}

.nav-link__menu .nav-link__menu-backbtn {
  cursor: pointer;
  margin: 0;
  transition: transform .2s;
  display: none;
  position: absolute;
  top: 2%;
  left: 2%;
}

.nav-link__menu .nav-link__menu-backbtn i {
  color: #fff;
}

.nav-link__menu .nav-link__menu-backbtn p {
  color: #fff;
  text-transform: capitalize;
  margin: 0 2px;
  font-size: 16px;
}

.nav-link__menu .nav-link__menu-backbtn:hover {
  transform: translateX(-2px);
}

.nav-link__menu h2 {
  text-align: center;
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 15px 0;
  font-size: 40px;
  font-weight: 200;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.nav-link__menu .spacer {
  margin-top: 15px;
}

.nav-link__menu .nav-link {
  color: #fff;
  text-transform: capitalize;
  text-align: center;
  width: 90%;
  border-radius: 3px;
  margin: 5px;
  padding: 5px;
  font-size: 16px;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  transition: color .15s, background-color .15s;
}

.nav-link__menu .nav-link:hover {
  color: #e4002b;
  background-color: #fff;
}

.nav-link__menu a {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.nav-link__menu .nav-link-header {
  color: #fff;
  text-transform: capitalize;
  text-align: center;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  font-size: 32px;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  transition: color .15s, background-color .15s;
}

.nav-link__menu .nav-link-header:hover {
  color: #e4002b;
  background-color: #fff;
}

.nav-link__menu .nav-link-header__main {
  font-size: 36px;
}

.square1, .countdown__square1 {
  height: 150px;
  width: 150px;
  opacity: 0;
  background-color: #a6a6a6;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: opacity .15s ease-in-out, transform .3s ease-in-out;
  display: flex;
  position: absolute;
  top: 4%;
  right: 50%;
  transform: translateX(1000px);
  box-shadow: 0 5px 10px #00000075;
}

.square2, .countdown__square2 {
  height: 220px;
  width: 220px;
  opacity: 0;
  background-color: #e4002b;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: opacity .15s ease-in-out, transform .3s ease-in-out;
  display: flex;
  position: absolute;
  top: 68%;
  right: 1%;
  transform: translateX(1000px);
  box-shadow: 0 10px 30px #0000008f;
}

.square3 {
  height: 175px;
  width: 175px;
  opacity: 0;
  background-color: #a6a6a6;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: opacity .15s ease-in-out, transform .3s ease-in-out;
  display: flex;
  position: absolute;
  top: 73%;
  right: 48%;
  transform: translateX(1000px);
  box-shadow: 0 5px 20px #0000008f;
}

.square4 {
  height: 100px;
  width: 100px;
  opacity: 0;
  background-color: #e4002b;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: opacity .15s ease-in-out, transform .3s ease-in-out;
  display: flex;
  position: absolute;
  top: 4%;
  right: 2%;
  transform: translateX(1000px);
  box-shadow: 0 5px 10px #00000065;
}

.nav-link__menu.move, .square1.move, .move.countdown__square1, .square2.move, .move.countdown__square2, .square3.move, .square4.move {
  opacity: 1;
  transform: translateX(0);
}

.nav-bar__menu-img {
  margin-right: 100px;
  display: none;
}

@media screen and (width >= 1750px) {
  .square1, .countdown__square1, .square3 {
    right: 43%;
  }

  .square4 {
    height: 150px;
    width: 150px;
  }
}

@media screen and (width >= 1535px) {
  .nav-bar__menu-img {
    display: inline;
  }

  .square1, .countdown__square1, .square3 {
    right: 48%;
  }

  .square4 {
    height: 150px;
    width: 150px;
  }

  .nav-link__menu {
    width: 800px;
    height: 600px;
  }
}

@media screen and (width <= 1535px) {
  .nav-bar__menu-img {
    width: 450px;
    display: inline;
  }
}

@media screen and (width >= 1880px) {
  .nav-bar__menu .spacer {
    margin-top: 40px;
  }

  .nav-bar__menu .nav-link {
    font-size: 20px;
  }

  .nav-bar__menu nav .link, .nav-bar__menu nav p {
    font-size: 48px;
  }

  .nav-bar__menu .nav-link__menu {
    width: 900px;
    height: 700px;
    top: 12%;
    right: 5%;
  }

  .nav-bar__menu .nav-link__menu h2 {
    font-size: 54px;
  }

  .square1, .countdown__square1, .square3 {
    height: 300px;
    width: 300px;
    right: 40%;
  }

  .square2, .countdown__square2, .square4 {
    height: 250px;
    width: 250px;
  }

  .spotlight {
    height: 200px;
  }

  .spotlight img {
    width: 200px;
  }

  .spotlight .text-container h3 {
    font-size: 34px;
  }

  .spotlight .text-container p {
    font-size: 20px;
  }
}

@media screen and (width >= 2460px) {
  .nav-bar {
    height: 90px;
    padding: 10px 40px;
  }

  .nav-bar .btn-group3 #menu-label {
    font-size: 2rem;
  }

  .menu-btn .menu-btn__line {
    width: 50px;
    height: 5px;
  }

  .nav-bar__menu .spacer {
    margin-top: 40px;
  }

  .nav-bar__menu .nav-link {
    font-size: 20px;
  }

  .nav-bar__menu nav .link, .nav-bar__menu nav p {
    font-size: 72px;
  }

  .nav-bar__menu .nav-link__menu {
    width: 1000px;
    height: 800px;
    top: 15%;
    right: 5%;
  }

  .nav-bar__menu .nav-link__menu h2 {
    font-size: 60px;
  }

  .square1, .countdown__square1, .square3 {
    height: 300px;
    width: 300px;
    right: 40%;
  }

  .square2, .countdown__square2, .square4 {
    height: 250px;
    width: 250px;
  }

  .spotlight {
    height: 200px;
  }

  .spotlight img {
    width: 220px;
  }

  .spotlight .text-container h3 {
    font-size: 34px;
  }

  .spotlight .text-container p {
    font-size: 20px;
  }
}

@media screen and (width >= 3900px) {
  .nav-bar__menu .spacer {
    margin-top: 40px;
  }

  .nav-bar__menu .nav-link {
    margin: 10px 5px;
    font-size: 32px;
  }

  .nav-bar__menu nav .link, .nav-bar__menu nav p {
    font-size: 72px;
  }

  .nav-bar__menu .nav-link__menu {
    width: 1200px;
    height: 1000px;
    top: 15%;
    right: 5%;
  }

  .nav-bar__menu .nav-link__menu h2 {
    font-size: 68px;
  }

  .square1, .countdown__square1, .square3 {
    height: 300px;
    width: 300px;
    right: 32%;
  }

  .square2, .countdown__square2, .square4 {
    height: 250px;
    width: 250px;
  }

  .spotlight {
    height: 215px;
  }

  .spotlight img {
    width: 248px;
  }

  .spotlight .text-container h3 {
    font-size: 38px;
  }

  .spotlight .text-container p {
    font-size: 24px;
  }
}

@media screen and (width <= 1370px) {
  .nav-bar {
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
    display: grid;
  }

  .nav-bar .btn-group2 {
    display: none;
  }

  .nav-bar__menu {
    overflow-y: visible;
  }

  .nav-bar__menu .mobile-nav-btns {
    display: flex;
  }

  .nav-bar__menu nav {
    flex-grow: 1;
    align-items: center;
    padding-top: 30px;
  }

  .nav-bar__menu nav .link, .nav-bar__menu nav p {
    text-align: center;
    margin: 12px 5px;
    font-size: 30px;
  }

  .nav-bar__menu nav .spacer-for-line {
    margin-bottom: 5px;
  }

  .nav-bar__menu nav .link-underline, .nav-bar__menu .square1, .nav-bar__menu .countdown__square1, .nav-bar__menu .square2, .nav-bar__menu .countdown__square2, .nav-bar__menu .square3, .nav-bar__menu .square4 {
    display: none;
  }

  .nav-link__menu {
    min-height: 100%;
    width: 100%;
    border-radius: 0;
    padding-bottom: 40px;
    top: 0;
    right: 0;
    transform: translateX(1500px);
  }

  .nav-link__menu .nav-link {
    font-size: 24px;
  }

  .nav-link__menu .nav-link__menu-backbtn {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

@media screen and (width <= 1073px) {
  .nav-bar__menu-img {
    display: none;
  }
}

@media screen and (width <= 1023px) {
  .nav-bar {
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
    display: grid;
  }

  .nav-bar .btn-group2 {
    display: none;
  }

  .nav-bar__menu nav .mobile-nav-btns {
    display: flex;
  }

  .nav-link__menu h2 {
    margin-top: 45px;
  }

  .nav-link__menu .nav-link {
    font-size: 24px;
  }
}

@media screen and (width <= 768px) {
  .spotlight {
    width: 90%;
  }

  .nav-bar .btn-group1 .nav-btn {
    display: none;
  }
}

@media screen and (width <= 550px) {
  .nav-bar {
    padding: 0 10px;
  }

  .nav-bar .btn-group3 #menu-label {
    font-size: 1rem;
  }

  .nav-bar__menu nav {
    padding-bottom: 85px;
  }

  .nav-bar__menu a {
    font-size: 20px;
  }

  .nav-bar__menu .nav-link {
    font-size: 12px;
  }

  .nav-bar__menu .nav-link-header {
    font-size: 18px;
  }

  .nav-bar__menu .nav-link-header__header {
    font-size: 22px;
  }

  .spotlight {
    width: 95%;
    height: 150px;
  }

  .spotlight img {
    width: 140px;
  }

  .spotlight .text-container {
    align-items: center;
  }

  .spotlight .text-container h3 {
    text-align: center;
    font-size: 18px;
  }

  .spotlight .text-container p {
    text-align: center;
    font-size: 12px;
    line-height: 14px;
  }
}

@media screen and (width <= 475px) {
  .spotlight .text-container {
    margin-left: 0;
  }
}

@media screen and (width <= 350px) {
  .menu-btn .menu-btn__line {
    width: 30px;
    height: 3px;
  }

  .menu-btn.close .menu-btn__line:first-child {
    transform: rotate(45deg)translate(5px, 6.5px);
  }

  .menu-btn.close .menu-btn__line:nth-child(3) {
    transform: rotate(-45deg)translate(5px, -6.5px);
  }

  .nav-bar .nav-btn {
    width: auto;
  }

  .nav-bar .btn-group3 #menu-label {
    margin-right: 3px;
  }
}

body {
  height: 100vh;
  overflow: hidden;
}

.programs__search-area {
  width: 22%;
  height: calc(100vh - 70px);
  float: left;
  background-color: #002149;
  border-right: 5px solid #002149;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transition: max-height .5s ease-in-out;
  display: flex;
  overflow-y: auto;
}

.programs__search-area .filter-control {
  width: 100%;
  cursor: pointer;
  display: none;
}

.programs__search-area .filter-control h3, .programs__search-area .filter-control i {
  font-size: 25px;
}

.programs__search-area .filter-control h3 {
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-right: 10px;
}

.programs__search-area .filter-control i {
  color: #fff;
}

.programs__search-area .degree-section {
  width: 100%;
  height: 100%;
}

.programs__search-area .degree-section__search-bar {
  min-width: 270px;
  max-width: 900px;
  width: 90%;
  background-color: #fff;
  border-radius: 20px;
  padding: 0 25px;
}

.programs__search-area .degree-section__search-bar .search-bar {
  height: 35px;
  width: 87%;
  border: 0;
  outline: 0;
}

.programs__search-area .degree-section__search-bar .loading-symbol {
  display: none;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.programs__search-area .degree-section__search-bar .loading-symbol.active {
  width: 27px;
  height: 27px;
  border: 5px solid #0000;
  border-color: #e4002b #0000 #0000 #e4002b;
  border-radius: 50%;
  transition: all .5s ease-in;
  animation-name: rotate;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  display: block;
}

.programs__search-area .degree-section__header h3 {
  color: #fff;
}

.programs__search-area .degree-section__header form {
  width: 100%;
  border-bottom: 5px solid #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  display: flex;
}

.programs__search-area .degree-section__header form .btn, .programs__search-area .degree-section__header form .btn__small-text {
  width: 60%;
  height: 40px;
  font-size: 1.5rem;
}

.programs__search-area .degree-section__header img {
  width: 32px;
  background: #fff;
  border-radius: 50%;
  margin-right: 10px;
  padding: 5px;
  transition: transform .3s ease-in-out;
  transform: rotateZ(180deg);
}

.programs__search-area h1 {
  color: #fff;
  text-align: left;
  letter-spacing: 2px;
  margin-left: 15px;
}

.programs__search-area a {
  text-transform: capitalize;
  color: #fff;
  padding: 10px 5px;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  transition: color .2s ease-in-out;
  display: block;
}

.programs__search-area a:hover {
  color: #e4002b;
}

.program-content {
  width: 78%;
  height: calc(100vh - 50px);
  float: left;
  overflow-x: hidden;
  overflow-y: auto;
}

.program__header {
  min-height: 550px;
  background-image: linear-gradient(#0000 75%, #fff 100%), url("header-collage.80837a65.webp");
  background-position: center;
  margin: 0 auto;
  padding-bottom: 80px;
  padding-left: 20px;
  padding-right: 20px;
}

.program__header .top-heading, .program__header .program__student-story .heading, .program__student-story .program__header .heading, .program__header .top-heading-small, .program__header .top-heading-left {
  color: #fff;
  text-shadow: 3px 3px 3px #000;
  font-size: 40px;
  font-weight: 400;
}

.program__header .sub-heading, .program__header .sub-heading-small, .program__header .sub-heading-left, .program__header .sub-heading-small-left {
  color: #fff;
  text-shadow: 3px 3px 3px #000;
  font-size: 30px;
  font-weight: 300;
}

.program__header .red-block {
  width: 350px;
  height: 350px;
  background-color: #e4002b;
  place-self: center;
  position: relative;
  box-shadow: 0 20px 50px #00000083;
}

.program__header .red-block img {
  height: 260px;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  right: -45%;
  transform: translate(-50%, -50%);
  box-shadow: 0 20px 30px #00000083;
}

@media screen and (width >= 2460px) {
  .programs__search-area, .program-content {
    height: calc(100vh - 90px);
  }
}

@media screen and (width <= 1100px) {
  .program__header {
    background-image: linear-gradient(#0000 75%, #fff 100%), url("header-mobile.5617c196.webp");
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .program__header .red-block {
    display: none;
  }

  .program__header * {
    text-align: center;
  }

  .program__header * .btn, .program__header * .btn__small-text {
    margin: 10px 0;
  }
}

.rest-of {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.program-header {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.program-section {
  align-items: stretch;
  padding-left: 10px;
  padding-right: 10px;
}

.program-column {
  width: 320px;
  padding: 20px;
}

.program-column img {
  margin-top: 15px;
}

.program-column-stretch {
  max-width: 640px;
  padding: 20px;
}

.program-column-stretch img {
  margin-top: 15px;
}

.padding-top-bot {
  padding: 50px 0;
}

.arrow-img {
  fill: #e4002b;
  width: 240px;
  height: 200px;
}

.flex-row.prog-index {
  flex-direction: column;
}

.keaton-doll {
  width: 300px;
  margin-left: 70px;
}

.program__student-story .heading {
  color: #fff;
}

.program__student-story .sub-heading, .program__student-story .sub-heading-small, .program__student-story .sub-heading-left, .program__student-story .sub-heading-small-left {
  color: #fff;
  letter-spacing: 2px;
}

.program-card {
  height: 150px;
  width: 360px;
  background-color: #002149;
  border-radius: 20px;
  align-items: center;
  margin: 20px;
  transition: transform .3s ease-in-out, box-shadow .3s ease-in-out;
  display: flex;
  overflow: hidden;
  box-shadow: 0 5px 15px #000;
}

.program-card img {
  width: 64px;
  background-color: #fff;
  border-radius: 10px;
  margin: 5px 15px;
  padding: 10px;
  transition: transform .3s ease-in-out;
  display: block;
  transform: scale(1.05);
}

.program-card .text-section {
  width: 100%;
  padding: 10px;
}

.program-card .text-section h3 {
  color: #e4002b;
  text-shadow: 0 0 5px #000;
  font-size: 1.8rem;
  transition: color .3s ease-in-out;
  display: contents;
}

.program-card .text-section h4 {
  color: #e4002b;
  text-shadow: 0 0 5px #000;
  margin: 5px auto;
  font-size: 1.5rem;
}

.program-card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 50px #00000063;
}

.program-card:hover img {
  transform: scale(1);
}

.dropdown-btn .dropdown-content {
  display: none;
}

.dropdown-btn .dropdown-content:hover, .dropdown-btn:hover .dropdown-content {
  display: block;
}

.course {
  opacity: 1;
  transition: opacity .2s ease-in-out;
}

.course-spacer {
  height: 150px;
}

.course.selected {
  background-color: #fff;
  transition: background-color .2s, box-shadow .2s;
  box-shadow: inset 0 0 12px 1px #00000070;
}

.course.selected .program-choice {
  color: #e4002b;
  transition: color .2s;
}

.course.hide {
  opacity: 0;
}

.for-search {
  padding: 0;
  overflow-x: hidden;
}

.for-search li {
  width: 100%;
  border-bottom: 2px solid #fff;
  line-height: 25px;
}

.for-search .list-break {
  height: 20px;
  background-color: #fff;
}

.no-matches i {
  color: #e4002b;
  margin-top: 20px;
  font-size: 40px;
}

.no-matches h3 {
  color: #e4002b;
  margin-bottom: 0;
  font-size: 30px;
  line-height: 1.2;
}

.no-matches p {
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

.question-answer h3 {
  letter-spacing: 1.5px;
  color: #002149;
  text-align: left;
  margin-bottom: 10px;
  font-size: 26px;
  font-weight: 700;
}

.question-answer p, .question-answer li {
  color: #002149;
  margin-top: 5px;
  line-height: 1.7;
}

@media screen and (width <= 1370px) {
  .programs__search-area {
    float: none;
    width: 100%;
    z-index: 5;
    min-height: 58px;
    max-height: 5vh;
    transition: max-height .5s ease-in-out;
    position: fixed;
    overflow-y: hidden;
    box-shadow: 0 5px 15px #0008;
  }

  .programs__search-area .filter-control {
    background-color: #002149;
    border-bottom: 2px solid #fff;
    display: flex;
    position: fixed;
  }

  .programs__search-area .filter-control h3 {
    color: #fff;
    font-size: 18px;
  }

  .programs__search-area .filter-control i {
    font-size: 18px;
  }

  .programs__search-area .degree-section {
    height: 100vh;
  }

  .programs__search-area .degree-section__header {
    padding-top: 58px;
  }

  .keaton-doll {
    display: none;
  }

  .programs__search-area.expanded {
    max-height: calc(100vh - 50px);
    overflow-y: auto;
  }

  .program-content {
    width: 100%;
    margin-top: 60px;
    overflow-y: auto;
  }
}

@media screen and (width <= 550px) {
  .program-card {
    margin: 20px 0;
  }

  .program-container {
    padding: 0;
  }
}

@media screen and (width <= 1370px) {
  .arrow-img {
    width: 150px;
    height: 400px;
    margin-bottom: -100px;
    transform: rotateZ(90deg);
  }
}

.countdown {
  min-height: 700px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.countdown-timer {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.countdown-timer__block {
  width: 240px;
  height: 150px;
  border: 2px solid #e4002b;
  border-radius: 15px;
  margin: 10px 20px;
  padding: 25px 30px;
}

.countdown-timer__block #countdown-timer-days, .countdown-timer__block #countdown-timer-hours, .countdown-timer__block #countdown-timer-minutes, .countdown-timer__block #countdown-timer-seconds {
  text-align: center;
  text-transform: uppercase;
  color: #002149;
  letter-spacing: 6px;
  margin: 0;
  font-size: 55px;
  font-weight: bold;
}

.countdown-timer__block-small {
  min-width: 140px;
  height: 122px;
  background-color: #fff;
  border: 2px solid #e4002b;
  border-radius: 15px;
  margin: 10px 20px;
  padding: 20px 5px;
}

.countdown-timer__block-small #countdown-timer-days, .countdown-timer__block-small #countdown-timer-hours, .countdown-timer__block-small #countdown-timer-minutes, .countdown-timer__block-small #countdown-timer-seconds {
  text-align: center;
  text-transform: uppercase;
  color: #002149;
  letter-spacing: 6px;
  text-shadow: 3px 3px 5px #00000067;
  margin: 0;
  font-size: 40px;
  font-weight: bold;
}

.prog-countdown-timer__block-small {
  min-width: 140px;
  background-color: #fff;
  border: 2px solid #e4002b;
  border-radius: 15px;
  margin: 10px;
  padding: 15px 10px;
}

.prog-countdown-timer__block-small #countdown-timer-days, .prog-countdown-timer__block-small #countdown-timer-hours, .prog-countdown-timer__block-small #countdown-timer-minutes, .prog-countdown-timer__block-small #countdown-timer-seconds {
  text-align: center;
  text-transform: uppercase;
  color: #002149;
  letter-spacing: 6px;
  text-shadow: 3px 3px 5px #00000067;
  margin: 0;
  font-size: 30px;
  font-weight: bold;
}

.countdown__square1 {
  opacity: 1;
  top: 330px;
  right: 4%;
  transform: translateX(0);
}

.countdown__square2 {
  opacity: 1;
  top: 300px;
  left: 3%;
  transform: translateX(0);
}

.home-countdown-block {
  max-width: 760px;
}

@media screen and (width >= 1800px) {
  .countdown__square1 {
    right: 6%;
  }

  .countdown__square2 {
    left: 6%;
  }
}

@media screen and (width >= 1450px) {
  .countdown__square1 {
    width: 250px;
    height: 250px;
    top: 275px;
    right: 4%;
  }

  .countdown__square2 {
    width: 300px;
    height: 300px;
    top: 250px;
    left: 3%;
  }
}

@media screen and (width <= 1229px) {
  .countdown__square1, .countdown__square2 {
    display: none;
  }
}

.program-collapsible {
  max-height: 72px;
  margin: 20px 0;
  transition: max-height .3s ease-in-out;
  overflow: hidden;
}

.program-collapsible .subheading, .program-collapsible i {
  transition: color .2s ease-in-out;
}

.program-collapsible i {
  font-size: 30px;
  transition: transform .3s ease-in-out;
}

.program-collapsible .program-collapsible__heading {
  max-width: 1300px;
  cursor: pointer;
  border: 2px solid #002149;
  border-radius: 15px;
  padding: 0 50px;
  transition: background-color .2s ease-in-out;
}

.program-collapsible:hover .program-collapsible__heading {
  background-color: #002149;
}

.program-collapsible:hover i, .program-collapsible:hover .subheading {
  color: #fff;
}

.program-collapsible.open {
  max-height: 2000px;
  padding-bottom: 15px;
}

.program-collapsible.open .program-collapsible__heading {
  background-color: #e4002b;
}

.program-collapsible.open i {
  color: #fff;
  transform: rotateZ(180deg);
}

.program-collapsible.open .turn-in {
  transform: rotateZ(-180deg);
}

.program-collapsible.open .subheading {
  color: #fff;
}

.online-course-container {
  display: block;
}

.online-course-container.hide {
  display: none;
}

@media screen and (width <= 550px) {
  .program-collapsible .program-collapsible__heading {
    padding: 0 7px;
  }

  .program-collapsible .program-collapsible__heading .subheading {
    margin-left: 5px;
    font-size: 20px;
  }

  .program-collapsible .program-collapsible__heading .subheading .hide-wording {
    display: none;
  }

  .program-collapsible .program-collapsible__heading i {
    margin-right: 10px;
    font-size: 20px;
  }

  .program-collapsible .program-collapsible__heading .turn-in {
    display: none;
  }
}

.faculty-bio a {
  margin: 10px 25px;
  text-decoration: none;
  display: block;
}

.faculty-bio__container {
  width: 250px;
  max-height: 300px;
  border: 3px solid #002149;
  border-radius: 25px;
  padding: 10px;
  transition: all .2s ease-in-out;
  box-shadow: 0 15px 40px #0000006e;
}

.faculty-bio__container:hover {
  border: 3px solid #e4002b;
  transform: scale(1.05);
  box-shadow: 0 10px 50px #0000004f;
}

.faculty-bio__container:hover img {
  border: 5px solid #002149;
}

.faculty-bio__container:hover h3 {
  color: #e4002b;
}

.faculty-bio__container img {
  width: 150px;
  max-height: 150px;
  object-fit: cover;
  object-position: center;
  border: 5px solid #e4002b;
  border-radius: 50%;
  margin-bottom: 0;
  transition: all .2s ease-in-out;
  box-shadow: 0 5px 10px #0000006e;
}

.faculty-bio__container h3 {
  text-align: center;
  transition: color .2s ease-in-out;
}

.form-directions {
  width: 80%;
  margin: 0 auto;
  padding-top: 100px;
}

.form-directions h1 {
  color: #e4002b;
}

.form-directions p {
  color: #002149;
  margin: 10px 0 0;
  font-size: 20px;
  font-weight: bold;
}

form {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.form-section {
  width: 90%;
  margin: 20px 10px;
}

.form-section h3 {
  letter-spacing: 2px;
  color: #e4002b;
  font-size: 26px;
}

.form-section p {
  color: #002149;
  font-size: 16px;
}

.form-group {
  flex-direction: column;
  justify-content: center;
  place-self: center;
  align-items: flex-start;
  margin: 15px auto;
  display: flex;
}

.form-group label {
  letter-spacing: 1.5px;
  color: #002149;
  padding-bottom: 15px;
  font-size: 24px;
  font-weight: bold;
}

.form-group input, .form-group select {
  width: 100%;
  color: #002149;
  border: 2px solid #002149;
  border-radius: 5px;
  outline: 0;
  padding: 5px 7px;
  font-size: 20px;
  transition: box-shadow .2s ease-in-out;
}

.form-group input:hover, .form-group select:hover {
  box-shadow: 0 0 5px 1px #0021496c;
}

.form-group input:focus, .form-group select:focus {
  box-shadow: 0 0 5px 1px #e4002a70;
}

.form-group h4 {
  letter-spacing: 1.5px;
  color: #e4002b;
  font-size: 24px;
}

.form-group input[data-error] {
  color: red;
  box-shadow: 0;
  background-color: #fdd;
  border-color: red;
}

.form-group input[data-error]:after {
  content: attr(data-error);
  color: red;
  position: absolute;
  top: 0;
}

.form-group[data-error] {
  position: relative;
}

.form-group[data-error] label {
  color: red;
}

.form-group[data-error] input, .form-group[data-error] select {
  color: red;
  box-shadow: 0;
  background-color: #facece;
  border-color: red;
}

.form-group[data-error]:after {
  content: attr(data-error);
  color: red;
  position: absolute;
  top: 105%;
  left: 10px;
}

.check-box {
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  display: flex;
}

.check-box input {
  width: auto;
  margin-right: 20px;
}

.check-box input:focus {
  box-shadow: 0 0 #e4002a70;
}

.check-box label {
  padding-bottom: 0;
}

.check-box .contact {
  max-width: 800px;
  font-size: 20px;
}

@media screen and (width <= 767px) {
  .form-section {
    text-align: center;
    width: 90%;
  }

  .form-section h3 {
    font-size: 30px;
  }

  .form-group {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .check-box label {
    text-align: center;
    font-size: 18px;
  }
}

.glide {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  width: 100%;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  white-space: nowrap;
  will-change: transform;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.glide__slides--dragging {
  -webkit-user-select: none;
  user-select: none;
}

.glide__slide {
  width: 100%;
  height: 100%;
  white-space: normal;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  flex-shrink: 0;
}

.glide__slide a {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.glide__arrows, .glide__bullets {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.glide--rtl {
  direction: rtl;
}

.glide__arrow {
  z-index: 2;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 .25em .5em #0000001a;
  opacity: 1;
  cursor: pointer;
  background-color: #0000;
  border: 2px solid #ffffff80;
  border-radius: 4px;
  padding: 9px 12px;
  line-height: 1;
  transition: opacity .15s, border .3s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 .25em .5em #0000001a;
}

.glide__arrow:focus {
  outline: none;
}

.glide__arrow:hover {
  border-color: #fff;
}

.glide__arrow--left {
  left: 2em;
}

.glide__arrow--right {
  right: 2em;
}

.glide__arrow--disabled {
  opacity: .33;
}

.glide__bullets {
  z-index: 2;
  list-style: none;
  display: inline-flex;
  position: absolute;
  bottom: 2em;
  left: 50%;
  transform: translateX(-50%);
}

.glide__bullet {
  width: 9px;
  height: 9px;
  cursor: pointer;
  background-color: #ffffff80;
  border: 2px solid #0000;
  border-radius: 50%;
  margin: 0 .25em;
  padding: 0;
  line-height: 0;
  transition: all .3s ease-in-out;
  box-shadow: 0 .25em .5em #0000001a;
}

.glide__bullet:focus {
  outline: none;
}

.glide__bullet:hover, .glide__bullet:focus {
  background-color: #ffffff80;
  border: 2px solid #fff;
}

.glide__bullet--active {
  background-color: #fff;
}

.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.gdpr {
  width: 100%;
  max-height: 80%;
  z-index: 100;
  opacity: 0;
  background-color: #fff;
  transition: opacity .5s ease-in-out, transform .5s ease-in-out;
  position: fixed;
  bottom: 0;
  overflow-y: auto;
  transform: translateY(1000px);
  box-shadow: 0 0 30px 10px #0000007c;
}

.gdpr h3 {
  margin: 0 15px 0 0;
}

.gdpr p {
  text-align: center;
  max-height: 250px;
  font-size: 10px;
}

.gdpr.open {
  opacity: 1;
  transform: translateY(0);
}

.translator {
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  display: flex;
  box-shadow: 0 20px 50px #00000071;
}

.translator a {
  color: #e4002b;
  margin: 0 10px 5px;
  font-weight: bold;
  transition: color .2s ease-in-out;
}

.translator a:hover {
  color: #002149;
}

.translator select {
  color: #002149;
  max-width: 90%;
  border: 2px solid #002149;
  border-radius: 20px;
  outline: 0;
  margin: 0 auto 15px;
  padding: 3px 7px;
}

.translator-fixed {
  z-index: 20;
  width: 250px;
  border-radius: 15px;
  transition: transform 1s ease-in-out;
  position: fixed;
  bottom: 70px;
  left: 5%;
  transform: translateY(50px);
  box-shadow: 0 0 10px 5px #00000062;
}

.translator-fixed.closed {
  transform: translateY(205px);
}

.translator-fixed .translator-tab {
  cursor: pointer;
  letter-spacing: 2px;
  width: 100%;
  background: #002149;
  border-radius: 15px 15px 0 0;
}

.translator-fixed .translator-tab h4 {
  font-size: 1.5rem;
}

.translator-fixed .translator-fixed__translate {
  background: #fff;
  border-radius: 0 0 15px 15px;
}

.translator-fixed .translator-fixed__translate h3 {
  text-align: center;
}

.translator-fixed .translator-fixed__translate select {
  color: #002149;
  width: 90%;
  border: 2px solid #002149;
  border-radius: 20px;
  outline: 0;
  margin-bottom: 15px;
  padding: 3px 7px;
}

.translator-fixed .translator-fixed__translate a {
  color: #e4002b;
  margin: 0 10px 5px;
  font-weight: bold;
  transition: color .2s ease-in-out;
}

.translator-fixed .translator-fixed__translate a:hover {
  color: #002149;
}

.translate-link {
  color: #002149;
  font-weight: bold;
  text-decoration: none;
}

a.gflag {
  vertical-align: middle;
  color: #002149;
  background-image: url("//gtranslate.net/flags/16.png");
  background-repeat: no-repeat;
  padding: 1px 5px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
}

a.gflag img {
  border: 0;
  margin: 0;
}

a.gflag:hover {
  background-image: url("//gtranslate.net/flags/16a.png");
}

#goog-gt-tt, .goog-te-banner-frame {
  display: none !important;
}

.goog-te-menu-value:hover {
  text-decoration: none !important;
}

body {
  top: 0 !important;
}

#google_translate_element2 {
  display: none !important;
}

footer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  display: flex;
  overflow: hidden;
}

footer h2 {
  text-align: center;
  letter-spacing: 2px;
  font-size: 48px;
  font-weight: 300;
}

footer h3 {
  color: #002149;
  letter-spacing: 1.5px;
}

.featured-links {
  width: 100%;
}

.featured-links .links {
  margin: 40px 0 25px;
  padding: 0 30px;
}

.featured-links .link {
  width: 130px;
  height: 130px;
  color: #fff;
  background-color: #002149;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  place-self: center;
  align-items: center;
  margin: 20px;
  transition: transform .2s ease-in-out, box-shadow .2s ease-in-out;
  display: flex;
  box-shadow: 0 15px 40px #00000081;
}

.featured-links .link:hover {
  transform: scale(1.1);
  box-shadow: 0 20px 60px #00000052;
}

.featured-links .link a {
  color: #fff;
  text-align: center;
  padding: 0 8px;
  font-weight: 700;
  line-height: 22px;
  text-decoration: none;
}

.featured-links .link:nth-of-type(2n) {
  background-color: #e4002b;
}

.footer-bottom {
  padding-top: 30px;
}

.footer-text-container {
  text-align: center;
}

.social-links .social-link {
  width: 60px;
  background-color: #fff;
  border: 2px solid #002149;
  margin: 5px 10px;
  padding: 10px;
  transition: border .3s ease-in-out, background-color .3s ease-in-out;
}

.social-links .social-link i {
  color: #002149;
  font-size: 32px;
  transition: color .3s ease-in-out;
}

.social-links .social-link:hover {
  background-color: #e4002b;
  border: 2px solid #e4002b;
}

.social-links .social-link:hover i {
  color: #fff;
}

.translator {
  width: 80%;
  max-width: 320px;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  display: flex;
  box-shadow: 0 20px 50px #00000071;
}

.translator h3 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 40px;
  font-weight: 300;
}

.translator h4 {
  color: #002149;
}

.translator select {
  border: 2px solid #002149;
  border-radius: 20px;
  outline: 0;
  margin-bottom: 15px;
  padding: 3px 5px;
}

.amazon-link {
  cursor: pointer;
  max-width: 300px;
  background-color: #002149;
  border-radius: 25px;
  margin: 15px 0;
  padding: 10px;
  transition: transform .2s ease-in-out;
  box-shadow: 0 10px 30px #0000007c;
}

.amazon-link:hover {
  transform: scale(1.05);
}

.copyright {
  background: linear-gradient(#fff 0%, #e4002b 5%);
}

.copyright p {
  color: #fff;
  text-align: center;
  padding: 30px 15px 75px;
  font-size: 12px;
}

@media screen and (width <= 1225px) {
  .featured-links .links .link {
    width: 120px;
    height: 120px;
  }

  .translator {
    margin: 60px 10px 80px;
  }
}

@media screen and (width <= 1023px) {
  .featured-links .links {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    display: grid;
  }

  .translator {
    margin: 60px 10px 80px;
  }
}

@media screen and (width <= 550px) {
  .translator {
    padding: 10px 0;
  }
}

@media screen and (width <= 475px) {
  .featured-links .link {
    width: 120px;
    height: 120px;
  }

  .featured-links .link a {
    font-size: 14px;
  }

  .amazon-link {
    margin: 15px 0;
  }
}

.preview {
  width: 100%;
}

.cover-img {
  width: 50%;
}

.row {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.row > .col {
  padding: 0 8px;
}

.col {
  float: left;
  width: 25%;
}

.modal {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #000;
  padding: 10px 62px 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.modal-content {
  width: 80%;
  max-width: 1200px;
  flex-direction: column;
  justify-content: center;
  margin: 125px auto auto;
  padding: 0;
  display: flex;
  position: relative;
}

.slide {
  display: none;
}

.image-slide {
  min-height: 100%;
  position: relative;
  height: auto !important;
}

.modal-preview {
  width: 100%;
}

.dots {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

img.preview, img.modal-preview {
  opacity: .6;
}

img.active, .preview:hover, .modal-preview:hover {
  opacity: 1;
}

img.hover-shadow {
  transition: all .3s;
}

.hover-shadow:hover {
  box-shadow: 0 4px 8px #0003, 0 6px 20px #00000030;
}

.close {
  color: #fff;
  font-size: 35px;
  font-weight: bold;
  top: 150px;
  right: 25px;
}

.close:hover, .close:focus {
  color: #999;
  cursor: pointer;
  text-decoration: none;
}

.previous, .next {
  cursor: pointer;
  width: auto;
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 0 3px 3px 0;
  margin-top: -50px;
  padding: 16px;
  font-size: 20px;
  font-weight: bold;
  transition: all .6s;
  position: absolute;
  top: 50%;
}

.next {
  border-radius: 3px 0 0 3px;
  right: 0;
}

.previous:hover, .next:hover {
  background-color: #000c;
}

/*# sourceMappingURL=prog-aa-general-studies.c6f8e8d5.css.map */
