@import "./scss-comps/reset.scss";
@import "./scss-comps/functions.scss";
@import "./scss-comps/baseclasses.scss";
@import "./scss-comps/nav-bar.scss";
@import "./scss-comps/programs.scss";
@import "./scss-comps/countdown.scss";
@import "./scss-comps/program-collapsible.scss";
@import "./scss-comps/program-faculty-spotlight.scss";
@import "./scss-comps/forms.scss";
@import "./scss-comps/glide.scss";
@import "./scss-comps/gdpr.scss";
@import "./scss-comps/translator.scss";
@import "./scss-comps/footer.scss";
@import "./scss-comps/lightbox.scss";
