.form-directions {
  padding-top: 100px;
  width: 80%;
  margin: 0 auto;

  h1 {
    color: $secondary;
  }

  p {
    color: $primary;
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0 0;
  }
}

form {
  @include flex(column, center, center);
  width: 100%;
}

.form-section {
  width: 90%;
  margin: 20px 10px;

  h3 {
    letter-spacing: 2px;
    font-size: 26px;
    color: $secondary;
  }

  p {
    font-size: 16px;
    color: $primary;
  }
}

.form-group {
  @include flex(column, center, flex-start);
  margin: 15px auto;
  align-self: center;
  justify-self: center;

  label {
    padding-bottom: 15px;
    font-size: 24px;
    letter-spacing: 1.5px;
    color: $primary;
    font-weight: bold;
  }

  input,
  select {
    width: 100%;
    outline: 0;
    padding: 5px 7px;
    border: 2px solid $primary;
    border-radius: 5px;
    transition: box-shadow 0.2s ease-in-out;
    font-size: 20px;
    color: $primary;

    &:hover {
      box-shadow: 0px 0px 5px 1px $primary-shadow;
    }

    &:focus {
      box-shadow: 0px 0px 5px 1px $secondary-shadow;
    }
  }

  h4 {
    font-size: 24px;
    letter-spacing: 1.5px;
    color: $secondary;
  }

  input[data-error] {
    background-color: rgb(255, 221, 221);
    color: red;
    border-color: red;
    box-shadow: 0;

    &::after {
      content: attr(data-error);
      position: absolute;
      top: 0;
      color: red;
    }
  }
}

.form-group[data-error] {
  position: relative;

  label {
    color: red;
  }

  input,
  select {
    background-color: rgb(250, 206, 206);
    color: red;
    border-color: red;
    box-shadow: 0;
  }

  &::after {
    content: attr(data-error);
    position: absolute;
    top: 105%;
    color: red;
    left: 10px;
  }
}

.check-box {
  @include flex(row, center, center);
  align-items: baseline;

  input {
    width: auto;
    margin-right: 20px;

    &:focus {
      box-shadow: 0px 0px 0px 0px $secondary-shadow;
    }
  }

  label {
    padding-bottom: 0;
  }

  .contact {
    font-size: 20px;
    max-width: 800px;
  }
}

@media screen and (max-width: 767px) {
  .form-section {
    text-align: center;
    width: 90%;

    h3 {
      font-size: 30px;
    }
  }

  .form-group {
    @include flex(column, center, center);
  }

  .check-box {
    label {
      text-align: center;
      font-size: 18px;
    }
  }
}
