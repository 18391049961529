.preview {
    width: 100%;
  }

  .cover-img {
    width: 50%;
  }
  
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .row > .col {
    padding: 0 8px;
  }
  
  .col {
    float: left;
    width: 25%;
  }
  
  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    padding: 10px 62px 0px 62px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
  }
  
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 125px auto auto;
    padding: 0 0 0 0;
    width: 80%;
    max-width: 1200px;
  }
  
  .slide {
    display: none;
  }
  
  .image-slide {
    position: relative;
    height: auto !important;
    min-height: 100%;
  }
  
  .modal-preview {
    width: 100%;
  }
  
  .dots {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  img.preview, img.modal-preview {
    opacity: 0.6;
  }
  
  img.active,
  .preview:hover,
  .modal-preview:hover {
    opacity: 1;
  }
  
  img.hover-shadow {
    transition: 0.3s;
  }
  
  .hover-shadow:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  } 
  
  .close {
    color: white;
    //position: absolute;
    top: 150px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer;
  }
  
  .previous,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
  }
  
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  
  .previous:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }