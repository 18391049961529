.faculty-bio {
  a {
    display: block;
    text-decoration: none;
    margin: 10px 25px;
  }

  &__container {
    width: 250px;
    max-height: 300px;
    padding: 10px;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.432);
    border-radius: 25px;
    border: 3px solid $primary;
    transition: all 0.2s ease-in-out;

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 10px 50px rgba(0, 0, 0, 0.308);
      border: 3px solid $secondary;

      img {
        border: 5px solid $primary;
      }

      h3 {
        color: $secondary;
      }
    }

    img {
      width: 150px;
      max-height: 150px;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
      border: 5px solid $secondary;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.432);
      margin-bottom: 0;
      transition: all 0.2s ease-in-out;
    }

    h3 {
      text-align: center;
      transition: color 0.2s ease-in-out;
    }
  }
}
