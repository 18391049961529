.translator {
  @include flex(column, center, center);
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.445);
  border-radius: 15px;
  padding: 10px 30px;

  h3 {
    text-align: center;
    font-size: 40px;
    margin-bottom: 0;
    margin-top: 10px;
    font-weight: 300;
  }

  h4 {
    color: $primary;
  }

  a {
    margin: 0 10px 5px;
    color: $secondary;
    font-weight: bold;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: $primary;
    }
  }

  select {
    outline: 0;
    border: 2px solid $primary;
    border-radius: 20px;
    padding: 3px 7px;
    color: $primary;
    max-width: 90%;
    margin: 0 auto 15px;
  }
}

.translator-fixed {
  position: fixed;
  z-index: 20;
  bottom: 70px;
  left: 5%;
  width: 250px;
  border-radius: 15px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.384);
  transform: translateY(50px);
  transition: transform 1s ease-in-out;

  &.closed {
    transform: translateY(205px);
  }

  .translator-tab {
    background: $primary;
    cursor: pointer;
    letter-spacing: 2px;
    border-radius: 15px 15px 0 0;
    width: 100%;

    h4 {
      font-size: 1.5rem;
    }
  }

  .translator-fixed__translate {
    background: white;
    border-radius: 0 0 15px 15px;

    h3 {
      text-align: center;
    }

    select {
      outline: 0;
      border: 2px solid $primary;
      border-radius: 20px;
      padding: 3px 7px;
      margin-bottom: 15px;
      color: $primary;
      width: 90%;
    }

    a {
      margin: 0 10px 5px;
      color: $secondary;
      font-weight: bold;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: $primary;
      }
    }
  }
}

.translate-link {
  text-decoration: none;
  color: $primary;
  font-weight: bold;
}

a.gflag {
  vertical-align: middle;
  font-size: 16px;
  padding: 1px 5px;
  background-repeat: no-repeat;
  background-image: url(//gtranslate.net/flags/16.png);
  text-decoration: none;
  color: $primary;
  font-weight: bold;
}
a.gflag img {
  border: 0;
  margin: 0;
}
a.gflag:hover {
  background-image: url(//gtranslate.net/flags/16a.png);
}
#goog-gt-tt {
  display: none !important;
}
.goog-te-banner-frame {
  display: none !important;
}
.goog-te-menu-value:hover {
  text-decoration: none !important;
}
body {
  top: 0 !important;
}
#google_translate_element2 {
  display: none !important;
}
