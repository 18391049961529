.gdpr {
  background-color: white;
  width: 100%;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.486);
  position: fixed;
  bottom: 0;
  z-index: 100;
  opacity: 0;
  transform: translateY(1000px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;

  h3 {
    margin: 0 15px 0 0;
  }

  p {
    font-size: 10px;
    text-align: center;
    max-height: 250px;
  }

  &.open {
    transform: translateY(0px);
    opacity: 1;
  }
}
