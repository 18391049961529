//break-points
$lap-top: 1440px;
$lap-top-mid: 1370px;
$lap-top-small: 1023px;
$tablet-large: 950px;
$tablet-small: 768px;
$phone-large: 550px;
$phone: 475px;
$phone-small: 350px;

@mixin grid($rows, $columns, $gap: 0px) {
  display: grid;
  grid-template-rows: repeat($rows, 1fr);
  grid-template-columns: repeat($columns, 1fr);
  gap: $gap;
}

@mixin flex($dir, $just, $align) {
  display: flex;
  flex-direction: $dir;
  justify-content: $just;
  align-items: $align;
}
