body {
  height: 100vh;
  overflow: hidden;
}

.programs__search-area {
  width: 22%;
  height: calc(100vh - 70px);
  float: left;
  border-right: 5px solid $primary;
  background-color: $primary;
  overflow-y: auto;
  @include flex(column, flex-start, flex-start);
  transition: max-height 0.5s ease-in-out;

  .filter-control {
    width: 100%;
    cursor: pointer;
    display: none;

    h3,
    i {
      font-size: 25px;
    }

    h3 {
      letter-spacing: 2px;
      text-transform: uppercase;
      margin-right: 10px;
    }

    i {
      color: white;
    }
  }

  .degree-section {
    width: 100%;
    height: 100%;

    &__search-bar {
      min-width: 270px;
      max-width: 900px;
      width: 90%;
      background-color: white;
      padding: 0 25px;
      border-radius: 20px;

      .search-bar {
        height: 35px;
        width: 87%;
        outline: 0;
        border: 0;
      }

      .loading-symbol {
        display: none;

        //loading animation keyframes
        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        @-webkit-keyframes rotate {
          from {
            -webkit-transform: rotate(0deg);
          }
          to {
            -webkit-transform: rotate(360deg);
          }
        }

        &.active {
          display: block;
          width: 27px;
          height: 27px;
          border: solid 5px $secondary;
          border-radius: 50%;
          border-right-color: transparent;
          border-bottom-color: transparent;
          -webkit-transition: all 0.5s ease-in;
          -webkit-animation-name: rotate;
          -webkit-animation-duration: 1s;
          -webkit-animation-iteration-count: infinite;
          -webkit-animation-timing-function: linear;
          transition: all 0.5s ease-in;
          animation-name: rotate;
          animation-duration: 1s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
      }
    }
  }

  .degree-section__header {
    h3 {
      color: white;
    }

    form {
      width: 100%;
      @include flex(column, center, center);
      border-bottom: 5px solid white;
      padding-bottom: 20px;

      .btn {
        width: 60%;
        font-size: 1.5rem;
        height: 40px;
      }
    }

    img {
      margin-right: 10px;
      background: white;
      padding: 5px;
      border-radius: 50%;
      width: 32px;
      transform: rotateZ(180deg);
      transition: transform 0.3s ease-in-out;
    }
  }

  h1 {
    color: white;
    text-align: left;
    margin-left: 15px;
    letter-spacing: 2px;
  }

  a {
    text-decoration: none;
    text-transform: capitalize;
    font-weight: bold;
    color: white;
    display: block;
    padding: 10px 5px;
    font-size: 1.5rem;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: $secondary;
    }
  }
}

.program-content {
  overflow-x: hidden;
  overflow-y: auto;
  width: 78%;
  height: calc(100vh - 50px);
  float: left;
}

.program__header {
  margin: 0 auto;
  min-height: 550px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 80px;
  background-image: linear-gradient(180deg, transparent 75%, white 100%),
    url(../img/header-collage.webp);
  background-position: center;
  // background-size: cover;

  .top-heading {
    font-size: 40px;
    font-weight: 400;
    color: white;
    text-shadow: 3px 3px 3px black;
  }

  .sub-heading {
    font-size: 30px;
    font-weight: 300;
    color: white;
    text-shadow: 3px 3px 3px black;
  }

  .red-block {
    position: relative;
    align-self: center;
    justify-self: center;
    width: 350px;
    height: 350px;
    background-color: $secondary;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.514);

    img {
      height: 260px;
      width: 100%;
      object-fit: cover;
      position: absolute;
      right: -45%;
      top: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0 20px 30px rgba(0, 0, 0, 0.514);
    }
  }
}

@media screen and (min-width: 2460px) {
  .programs__search-area {
    height: calc(100vh - 90px);
  }

  .program-content {
    height: calc(100vh - 90px);
  }
}

@media screen and (max-width: 1100px) {
  .program__header {
    @include flex(column, center, center);
    background-image: linear-gradient(180deg, transparent 75%, white 100%),
      url(../img/header-mobile.webp);

    .red-block {
      display: none;
    }

    * {
      text-align: center;

      .btn {
        margin: 10px 0;
      }
    }
  }
}

.rest-of {
  @include flex(column, center, center);
}

.program-header {
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
}

.program-section {
  padding-right: 10px;
  padding-left: 10px;
  align-items: stretch;
}

.program-column {
  width: 320px;
  padding: 20px 20px;

  img {
    margin-top: 15px;
  }
}

.program-column-stretch {
  max-width: 640px;
  padding: 20px;

  img {
    margin-top: 15px;
  }
}

.padding-top-bot {
  padding: 50px 0;
}

.arrow-img {
  fill: $secondary;
  width: 240px;
  height: 200px;
}

.flex-row.prog-index {
  flex-direction: column;
}

.keaton-doll {
  margin-left: 70px;
  width: 300px;
}

.program__student-story {
  .heading {
    @extend .top-heading;
    color: white;
  }

  .sub-heading {
    @extend .sub-heading;
    color: white;
    letter-spacing: 2px;
  }
}

//program-cards

.program-card {
  overflow: hidden;
  margin: 20px;
  height: 150px;
  width: 360px;
  border-radius: 20px;
  box-shadow: 0 5px 15px black;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background-color: $primary;
  display: flex;
  align-items: center;

  img {
    width: 64px;
    display: block;
    margin: 5px 15px;
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
    padding: 10px 10px;
    background-color: white;
    border-radius: 10px;
  }

  .text-section {
    width: 100%;
    padding: 10px;

    h3 {
      color: $secondary;
      font-size: 1.8rem;
      display: contents;
      transition: color 0.3s ease-in-out;
      text-shadow: 0 0 5px black;
    }

    h4 {
      color: $secondary;
      font-size: 1.5rem;
      margin: 5px auto;
      text-shadow: 0 0 5px black;
    }
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.39);

    img {
      transform: scale(1);
    }

    // .text-section {
    //   h2 {
    //     color: $primary;
    //   }
    // }
  }
}

//dropdown course
.dropdown-btn {
  .dropdown-content {
    display: none;
  
    &:hover {
      display: block;
    }
  }
  
  &:hover {
    .dropdown-content {
      display: block;
    }
  }
}

//course filter ani's

.course {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.course-spacer {
  height: 150px;
}

.course.selected {
  background-color: white;
  box-shadow: inset 0px 0px 12px 1px rgba(0, 0, 0, 0.438);
  transition: background-color 0.2s ease, box-shadow 0.2s ease;

  .program-choice {
    color: $secondary;
    transition: color 0.2s ease;
  }
}

.course.hide {
  opacity: 0;
}

.for-search {
  overflow-x: hidden;
  padding: 0;

  li {
    border-bottom: 2px solid white;
    width: 100%;
    line-height: 25px;
  }

  .list-break {
    height: 20px;
    background-color: white;
  }
}

//no matches text

.no-matches {
  i {
    color: $secondary;
    font-size: 40px;
    margin-top: 20px;
  }

  h3 {
    color: $secondary;
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: 0;
  }

  p {
    font-weight: bold;
    text-align: center;
    color: white;
    font-size: 20px;
  }
}

.question-answer {
  h3 {
    letter-spacing: 1.5px;
    color: $primary;
    font-weight: 700;
    font-size: 26px;
    margin-bottom: 10px;
    text-align: left;
  }

  p,
  li {
    color: $primary;
    line-height: 1.7;
    margin-top: 5px;
  }
}

@media screen and (max-width: $lap-top-mid) {
  .programs__search-area {
    float: none;
    position: fixed;
    width: 100%;
    overflow-y: hidden;
    z-index: 5;
    min-height: 58px;
    max-height: 5vh;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.534);
    transition: max-height 0.5s ease-in-out;

    .filter-control {
      display: flex;
      position: fixed;
      background-color: $primary;
      border-bottom: 2px solid white;

      h3 {
        color: white;
        font-size: 18px;
      }

      i {
        font-size: 18px;
      }
    }

    .degree-section {
      height: 100vh;
    }

    .degree-section__header {
      padding-top: 58px;
    }
  }

  .keaton-doll {
    display: none;
  }

  .programs__search-area.expanded {
    max-height: calc(100vh - 50px);
    overflow-y: auto;
  }

  .program-content {
    overflow-y: auto;
    width: 100%;
    margin-top: 60px;
  }

  // .arrow-img {
  //   transform: rotateZ(90deg);
  //   margin-top: -100px;
  //   width: 150px;
  // }
}

@media screen and (max-width: $phone-large) {
  // .arrow-img {
  //   width: 100px;
  //   margin-top: -120px;
  // }

  .program-card {
    margin: 20px 0;
  }

  .program-container {
    padding: 0px;
  }
}

@media screen and (max-width: 1370px) {

  .arrow-img {
    transform: rotateZ(90deg);
    width: 150px;
    margin-bottom: -100px;
    height: 400px
  }
}
